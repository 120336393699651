import AutocompleteMultiselect from 'components/autocomplete-multiselect';
import { useActionModal, useConfirmationModal } from 'components/modals';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import {
  useEffect,
  useState,
  MouseEvent,
  useCallback,
  useMemo,
  ChangeEvent,
  useRef,
} from 'react';
import { Typography } from '@mui/material';
import { useCurrentCollection } from 'scenes/nuevo-collection/hooks';
import { isNotNull } from 'services/utils/type-guards/generic';
import { assembleBulkOperation } from 'services/utils/assemble-bulk-operation';
import {
  getSuccessCount,
  recursiveJobRequest,
} from 'services/ozmo-api/utils/recursive-job-request';
import { useContentEntry } from 'services/hooks/api-hooks';
import { SelectedAttributes } from 'components/attribute-selector';
import { AnswerExistsError } from 'scenes/add-content/error';
import { DuplicateContentDialogContent } from 'components/duplicate-content-entry-dialog/dialog-content';
import {
  contentEntryToAttributes,
  isListEqual,
} from 'components/duplicate-content-entry-dialog/utils';
import { Attributes } from 'scenes/add-content/modal-body';
import { useDuplicateContentEntry } from 'components/duplicate-content-entry-dialog/hooks';
import { isAnswerExistsError } from 'scenes/add-content/utils';

type Args = {
  collectionId: number;
  contentEntryId: number;
  categoryId: number;
  answerTitle: string;
};

const KEY_MAP: Record<keyof Attributes, string> = {
  Device: 'deviceIds',
  DeviceType: 'deviceTypeIds',
  Manufacturer: 'manufacturerIds',
  OperatingSystem: 'operatingSystemIds',
  OperatingSystemRelease: 'operatingSystemReleaseIds',
  OperatingSystemVersion: 'operatingSystemVersionIds',
};

export const useLocalizedReferenceActions = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return {
    anchorEl,
    isOpen: Boolean(anchorEl),
    handleClose,
    handleOpen,
  };
};

export const useRemoveItemAction = ({
  collectionId,
  contentEntryId,
  categoryId,
  answerTitle,
}: Args) => {
  const dispatchToast = useAppToast();
  const api = useOzmoApiService();

  const handleRemove = async () => {
    const success = await api.Collection.deleteReferenceFromCategoryAsync(
      collectionId,
      categoryId,
      contentEntryId
    );
    success &&
      dispatchToast({
        level: 'success',
        message: 'Success! Answer removed from category.',
      });
  };
  const modalMessage = (
    <>
      {`You are about to remove this instance of `}
      <Typography component="span" fontWeight="bold">
        {answerTitle}
      </Typography>
      {`. Any other instances of this answer within this collection will remain. This action will only remove the answer, not delete it from the system.`}
    </>
  );
  const { modal, openModal } = useConfirmationModal({
    onConfirm: handleRemove,
    modalProps: {
      title: 'You are about to remove this answer',
      message: modalMessage,
      confirmButtonColor: 'danger',
      confirmButtonText: 'Yes, remove answer',
      closeButtonText: 'No, keep answer',
    },
  });

  return {
    handleRemoveItemClick: openModal,
    removeItemModal: modal,
  };
};

type AutocompleteOption = { id: number; name: string };
type AddToCategoryArgs = {
  contentEntryId: number;
  collectionId: number;
  answerTitle: string;
};
export const useAddToCategoryAction = ({
  contentEntryId,
  collectionId,
  answerTitle,
}: AddToCategoryArgs) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const { collection } = useCurrentCollection();
  const categories = collection?.categories;
  const [selectedCategories, setSelectedCategories] = useState<
    AutocompleteOption[]
  >([]);

  useEffect(() => {
    if (categories) {
      const categoriesContainingReference = categories
        .map(({ id, name, contentEntries }) => {
          if (contentEntries.find((c) => c.id === contentEntryId)) {
            return { id, name };
          }
          return null;
        })
        .filter(isNotNull);
      setSelectedCategories(categoriesContainingReference);
    }
  }, [categories, contentEntryId]);

  const handleConfirm = useCallback(async () => {
    const categoryIdsToAddTo = selectedCategories.map((c) => c.id);
    const categoryIdsToRemoveFrom = categories.reduce<number[]>((acc, cur) => {
      if (categoryIdsToAddTo.includes(cur.id)) {
        return acc;
      }
      return [...acc, cur.id];
    }, []);

    const operations = [
      ...categoryIdsToAddTo.map((categoryId) =>
        assembleBulkOperation(
          categoryId,
          'add',
          '/content_entry_ids/-',
          contentEntryId
        )
      ),
      ...categoryIdsToRemoveFrom.map((categoryId) =>
        assembleBulkOperation(
          categoryId,
          'remove_value',
          '/content_entry_ids',
          contentEntryId
        )
      ),
    ];

    const { job } = await api.Collection.bulkAsync(collectionId, operations);
    const response = await api.Collection.refetchedOperationAsync(
      () => recursiveJobRequest<BulkOperationJobResponse>(job),
      collectionId
    );

    const successCount = getSuccessCount(response);

    if (successCount === operations.length) {
      dispatchToast({
        level: 'success',
        message: 'Success! Categories updated.',
      });
      return true;
    }
    dispatchToast({
      level: 'error',
      message: `We were unable to recategorize ${answerTitle}`,
    });
    console.error(response);
    return false;
  }, [
    answerTitle,
    api.Collection,
    categories,
    collectionId,
    contentEntryId,
    dispatchToast,
    selectedCategories,
  ]);

  const handleChange = (_: any, options: AutocompleteOption[]) => {
    setSelectedCategories(options);
  };
  const { modal, openModal } = useActionModal({
    modalProps: {
      title: 'Assign an answer to a category or categories',
      confirmButtonText: 'Update categories',
    },
    onConfirm: handleConfirm,
    modalContent: (
      <AutocompleteMultiselect<AutocompleteOption>
        label="Category"
        onChange={handleChange}
        initialSelectedOptions={selectedCategories}
        options={categories?.map(({ id, name }) => ({ id, name })) ?? []}
      />
    ),
  });
  return {
    categoriesModal: modal,
    handleAddToCategoryClick: openModal,
  };
};

export const useDuplicateAction = (
  contentEntryId: number,
  categoryId: number,
  collectionId: number,
  answerTitle: string
) => {
  const { contentEntry, isLoading } = useContentEntry(contentEntryId);
  const dispatchToast = useAppToast();
  const [selectedSpaceId, setSelectedSpaceId] = useState(contentEntry?.spaceId);
  const [selectedCategories, setSelectedCategories] = useState<
    AutocompleteOption[]
  >([]);
  const [attributes, setAttributes] = useState<Partial<SelectedAttributes>>({});
  const [answerExistsError, setAnswerExistsError] = useState<
    AnswerExistsError | undefined
  >();
  const [shouldFetch, setShouldFetch] = useState(false);
  const { onDuplicate, isDuplicating } = useDuplicateContentEntry(
    contentEntryId
  );
  const { collection } = useCurrentCollection();
  const categories = collection?.categories;
  useEffect(() => {
    const currentCategory = categories?.find((cat) => cat.id === categoryId);
    if (categories && currentCategory) {
      setSelectedCategories([
        {
          id: categoryId,
          name: currentCategory.name,
        },
      ]);
    }
  }, [categories, categoryId]);

  const steptextOnlyCheckboxRef = useRef<HTMLInputElement>(null);

  const api = useOzmoApiService();

  const initialAttributes = useMemo(
    () => contentEntryToAttributes(contentEntry),
    [contentEntry]
  );

  const canDuplicate = useMemo(() => {
    if (selectedCategories?.length < 1) return false;
    if (contentEntry?.spaceId !== selectedSpaceId) return true;
    if (!initialAttributes || !attributes) return false;

    const allAttributesEqual = Object.entries(KEY_MAP).every(
      ([initialKey, attributeKey]) => {
        return isListEqual(
          initialAttributes[initialKey as keyof Attributes],
          attributes[attributeKey]
        );
      }
    );

    return !allAttributesEqual;
  }, [
    initialAttributes,
    attributes,
    contentEntry?.spaceId,
    selectedSpaceId,
    selectedCategories,
  ]);

  useEffect(() => {
    if (contentEntry?.spaceId) {
      setSelectedSpaceId(contentEntry.spaceId);
      setAttributes(
        contentEntryToAttributes(contentEntry) as SelectedAttributes
      );
    }
  }, [isLoading, contentEntry]);

  const handleAttributesChange = useCallback(
    (selectedAttributes: SelectedAttributes) => {
      setAttributes(selectedAttributes as any);
      setAnswerExistsError(undefined);
    },
    []
  );

  const handleSpaceIdChange = useCallback(
    (spaceId: number | string) => {
      const parsedId =
        typeof spaceId === 'number' ? spaceId : parseInt(spaceId, 10);
      if (parsedId !== selectedSpaceId) {
        setSelectedSpaceId(parsedId);
        setAnswerExistsError(undefined);
      }
    },
    [selectedSpaceId]
  );

  const handleCategoriesChange = useCallback(
    (_: ChangeEvent<{}>, newCatogories: AutocompleteOption[]) => {
      setSelectedCategories(newCatogories);
      setAnswerExistsError(undefined);
    },
    []
  );

  const handleConfirm = async () => {
    // if confirming after a duplicate error response, use the duplicate
    if (answerExistsError) {
      handleDuplicateComplete?.(answerExistsError.existingAnswerId);
      setAnswerExistsError(undefined);
      return;
    }

    if (onDuplicate) {
      const duplicatedContent = await onDuplicate(
        selectedSpaceId,
        attributes,
        steptextOnlyCheckboxRef.current?.checked
      );

      if (isAnswerExistsError(duplicatedContent)) {
        setAnswerExistsError(duplicatedContent);
        throw new Error();
      }

      if (handleDuplicateComplete) {
        handleDuplicateComplete(duplicatedContent?.id!);
      }
    }
  };

  const handleDuplicateComplete = async (duplicatedContentEntryId: number) => {
    setShouldFetch(false);
    const categoryIdsToAddTo = selectedCategories.map((c) => c.id);

    const operations = [
      ...categoryIdsToAddTo.map((catId) =>
        assembleBulkOperation(
          catId,
          'add',
          '/content_entry_ids/-',
          duplicatedContentEntryId
        )
      ),
    ];

    const { job } = await api.Collection.bulkAsync(collectionId, operations);
    const response = await api.Collection.refetchedOperationAsync(
      () => recursiveJobRequest<BulkOperationJobResponse>(job),
      collectionId
    );

    const successCount = getSuccessCount(response);

    if (successCount !== operations.length) {
      dispatchToast({
        level: 'error',
        message: `We were unable to recategorize ${answerTitle}`,
      });
      console.error(response);
      return false;
    }
    return true;
  };
  const handleClose = useCallback(() => {
    setAnswerExistsError(undefined);
  }, [setAnswerExistsError]);

  const handleDuplicateClick = () => {
    setShouldFetch(true);
    openModal();
  };

  const { modal, openModal } = useActionModal({
    closeOnReject: false,
    onConfirm: handleConfirm,
    onRefuse: handleClose,
    disableConfirmButtonOnClick: !answerExistsError,
    modalContent: (
      <DuplicateContentDialogContent
        contentEntry={shouldFetch ? contentEntry : null}
        isLoading={isLoading}
        isDuplicating={isDuplicating}
        handleAttributesChange={handleAttributesChange}
        handleSpaceIdChange={handleSpaceIdChange}
        handleCategoriesChange={handleCategoriesChange}
        selectedSpaceId={selectedSpaceId}
        selectedCategories={selectedCategories}
        answerExistsError={answerExistsError}
        initialAttributes={initialAttributes}
        categories={categories}
        steptextOnlyCheckboxRef={steptextOnlyCheckboxRef}
      />
    ),
    modalProps: {
      title: 'You are about to duplicate an answer',
      confirmButtonText: answerExistsError
        ? 'Use existing answer'
        : 'Duplicate answer',
      maxWidth: 'md',
      disableConfirmButton: !canDuplicate,
    },
  });

  return {
    modal,
    handleDuplicateClick,
  };
};
