import queryString from 'query-string';
import { useCallback, useRef, useMemo } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  QueryKey,
} from '@tanstack/react-query';
import { ozmoApiRequest } from 'services/ozmo-api';
import snakeToCamelCase from 'services/utils/convert-object-keys-snake-to-camel';
import keysToSnake, {
  arrayToKeyObject,
} from 'services/utils/convert-object-keys-camel-to-snake';
import { useAuthLogout } from 'scenes/google-auth';
import { appToastDispatch } from 'contexts/app-toast-context/app-toast-context';
import { isPermissionDeniedError } from 'services/utils/type-guards/is-permission-denied-error';

export type UrlParamOptions = {
  page?: number;
  perPage?: number;
  allPages?: boolean;
  limit?: number;
  findContentType?: string;
  findName?: string;
  asCollection?: boolean;
  languageId?: number;
  referencedContentEntryId?: number;
  steptextOnly?: boolean;
};

export const generateQueryKey = (
  id: number | '/',
  resourcePath: string,
  urlParamOptions: UrlParamOptions = {},
  embedOptions: string[] = []
) => {
  const snakeCaseOptions = keysToSnake(urlParamOptions);
  const snakeCaseEmbeds = keysToSnake(arrayToKeyObject(embedOptions));

  // if there are no embeds, return an array without them
  // otherwise, the key will include an empty {} which will not
  // partially match on invalidation with keys including embeds.
  if (Object.keys(snakeCaseEmbeds).length === 0) {
    return [resourcePath, { id, ...snakeCaseOptions }];
  }
  return [resourcePath, { id, ...snakeCaseOptions }, snakeCaseEmbeds];
};

const handleApiError = (e: Error) => {
  if (isPermissionDeniedError(e)) {
    appToastDispatch({
      level: 'error',
      message:
        "You don't have permission to perform this action. Please contact your team lead.",
    });
  }
  throw e;
};

export const getData = async (
  resource: string,
  embed: string[] = [],
  { queryKey }: any,
  options: UrlParamOptions = {}
): Promise<any> => {
  const snakedOptions = keysToSnake(options);
  const totalOptions = { ...snakedOptions, embed, all_pages: undefined }; // eslint-disable-line camelcase
  const parsedOptions = queryString.stringify(totalOptions, {
    arrayFormat: 'comma',
  });
  const shouldRequestHeaders =
    'page' in snakedOptions || 'all_pages' in snakedOptions;

  const data = ozmoApiRequest(
    `${resource}/${queryKey[1].id === '/' ? '' : queryKey[1].id}${
      parsedOptions === '' ? '' : `?${parsedOptions}`
    }`,
    undefined as any,
    'GET',
    shouldRequestHeaders
  ).catch(handleApiError);

  if ('all_pages' in snakedOptions) {
    const { data: firstPage, response } = await data;
    const perPage = Number(response.headers.get('X-Per-Page'));
    const totalPages = Math.ceil(
      Number(response.headers.get('X-Total')) / perPage
    );

    let allData = [].concat(firstPage);

    // only call another page if one actually exists
    // this prevents a call loop from occuring when an endpoint is called that
    // has fewer returned results than the perPage limit.
    if (perPage && typeof totalPages === 'number') {
      for (let page = 2; page <= totalPages; page++) {
        const parsedOptions = queryString.stringify(
          { ...totalOptions, page, per_page: perPage }, // eslint-disable-line camelcase
          { arrayFormat: 'comma' }
        );

        const pageData = await ozmoApiRequest(
          `${resource}/${queryKey[1].id === '/' ? '' : queryKey[1].id}${
            parsedOptions === '' ? '' : `?${parsedOptions}`
          }`,
          undefined as any,
          'GET'
        );

        allData = allData.concat(pageData);
      }
    }

    return allData;
  }

  return data;
};

export const createData = (
  resource: string,
  data: any,
  embed: string[] = [],
  options: UrlParamOptions = {},
  stringifyBody?: boolean,
  customHeaders?: any
): Promise<any> => {
  const parsedOptions = queryString.stringify(
    keysToSnake({ ...options, embed }),
    {
      arrayFormat: 'comma',
    }
  );
  const shouldRequestHeaders = 'page' in options;
  return ozmoApiRequest(
    `${resource}${parsedOptions === '' ? '' : `?${parsedOptions}`}`,
    data,
    'POST',
    shouldRequestHeaders,
    stringifyBody,
    customHeaders
  ).catch(handleApiError);
};

export const updateData = (
  resource: string,
  { id, ...data }: any,
  ifUnmodifiedSince?: string
): Promise<any> =>
  ozmoApiRequest(
    `${resource}/${id}`,
    { ...data },
    'PATCH',
    false,
    true,
    ifUnmodifiedSince &&
      // TODO: Remove when useStaleWriteRejection configcat flag is removed
      window.ozmoAuthoringFlags?.useStaleWriteRejection && {
        'If-Unmodified-Since': new Date(ifUnmodifiedSince).toUTCString(),
      }
  ).catch(handleApiError);

export const deleteData = (resource: string, id?: number): Promise<any> =>
  ozmoApiRequest(
    `${resource}/${id ? id : ''}`,
    undefined as any,
    'DELETE'
  ).catch(handleApiError);

export const updateCacheOptimistically = (
  queryClient: any,
  queryKey: QueryKey,
  newVersion: any
): any => {
  // Get a copy of the current value so we can rollback to it if the server mutation fails
  const previousEntry = queryClient.getQueryData(queryKey);
  // Convert the data from the snake-case the API expcets to camelCase that we prefer
  const camelCasedNewVersion = snakeToCamelCase(newVersion);
  camelCasedNewVersion.updatedAt = new Date().toISOString();
  camelCasedNewVersion.isOptimistic = true;

  // Optimistically update the cache
  queryClient.setQueryData(queryKey, {
    ...previousEntry,
    ...camelCasedNewVersion,
  });

  // Return the previous entry, this will be passed as the last parameter to the onError handler
  return previousEntry;
};

export const removeFromCacheOptimistically = (
  queryClient: any,
  queryKey: any
): any => {
  // Get a copy of the current value so we can rollback to it if the server mutation fails
  const previousEntry = queryClient.getQueryData(queryKey);

  // Optimistically remove from the cache
  queryClient.removeQueries(queryKey);

  // Return the previous entry, this will be passed as the last parameter to the onError handler
  return previousEntry;
};

export const onMutationError = (
  queryClient: any,
  queryKey: QueryKey,
  error: any,
  variables: any,
  previousVersion: any
): void => {
  if (process.env.NODE_ENV !== 'test') {
    console.log(
      `An error occurred processing mutation for ${JSON.stringify(queryKey)}: `,
      error
    );
    console.log('Called with variables: ', variables);
    console.log('Rolling back optimistic updated to: ', previousVersion);
  }
  queryClient.setQueryData(queryKey, previousVersion);
  queryClient.invalidateQueries(queryKey);
};

export const onUpdateSuccess = (
  queryClient: any,
  queryKey: QueryKey,
  data: any,
  variables: any,
  previousVersion: any
): void => {
  // On success, merge in the changes- we do this because unfortunately right now
  // the PUT/PATCH endpoints do not respect/return the embed options
  // Remove the isOptimistic flag because this is going to be merged in with the actual response
  const { isOptimistic, ...optimisticVersion } = queryClient.getQueryData(
    queryKey
  );
  queryClient.setQueryData(queryKey, {
    ...optimisticVersion,
    ...data,
  });
};

// After deleting a model succesfully, cancel any pending queries that
// are already processing/pending that might add it back to the cache
export const onDeleteSuccess = (queryClient: any, queryKey: any): void => {
  queryClient.cancelQueries(queryKey);
};

export type Pagination = {
  total: number | null;
  pages: number | null;
  page: number | null;
  perPage: number | null;
};
export interface UseQueryCacheResponse {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isUpdating: boolean;
  isUpdateSuccess: boolean;
  isUpdateError: boolean;
  isFetching: boolean;
  reset: Function;
  update: Function;
  delete: Function;
  refetch: Function;
  updateCacheOnly: Function;
  error: any;
  query: any;
  data: any;
  pagination: Pagination;
}

export type UseQueryOptionsWithPrefetch = {
  prefetchData?: boolean;
} & UseQueryOptions;

/**
 * useQueryCache() custom hook.  This is not intended to be use directly, though it can be
 * The intended usage is by using useOzmoApiService() which implements this hook in a more user-friendly way.
 * See it's documentation for details.
 *
 * @param {Number} id - The ID of the object to fetch
 * @param {String} resource - The path to the resource on the server (e.g.: authoring/content_entries)
 * @param {Array<String>} embed - An array of strings to append to the request to be embeded in the server response
 *  (e.g: ['space','devices']).  Will be transformed into "resource/id?embed=spaces,devices"
 * @param {Object} config - Optional config dictionary of react-query config params
 * @param {Object} options - Option dictionary of URL paramaters to append to the query (e.g: page, perPage)
 * @return {useQueryCacheResponse}
 */
const useQueryCache = (
  id: number | '/',
  resource: string,
  embed: string[],
  config: UseQueryOptionsWithPrefetch,
  options?: UrlParamOptions
): UseQueryCacheResponse => {
  const queryClient = useQueryClient();
  const logout = useAuthLogout();

  const snakeCaseOptions = keysToSnake(options);
  // Uniquely identify a query in the cache
  // Set the first entry in the array to the resource path of the model
  // Set the second entry to an object with the id of the model
  // e.g.: ['authoring/content_entries', { id: 123 }]
  const queryKey = useMemo(
    () => generateQueryKey(id, resource, options, embed),
    [id, resource, options, embed]
  );

  const hasValidId = id !== null && id !== undefined;
  const pagination = useRef<Pagination>({
    perPage: null,
    total: null,
    pages: null,
    page: null,
  });
  const isPaginated = 'page' in (snakeCaseOptions ?? {});
  const keepPreviousData = isPaginated;

  const retry = (failureCount: number, error: any) => {
    if (error.code === 401) {
      logout();
      return false;
    }
    // Don't retry failed queries in the test environment
    if (process.env.NODE_ENV === 'test') {
      return false;
    }
    return failureCount <= 2;
  };

  // To get the model
  const queryResult = useQuery<unknown, unknown, BaseModel, QueryKey>(
    queryKey, // how to uniquely identify the query
    (args) => getData(resource, embed, args, snakeCaseOptions), // how to get the data
    {
      enabled: hasValidId,
      keepPreviousData,
      ...config,
      select: (data: any) => {
        if (isPaginated) {
          pagination.current.total = parseInt(
            data.response.headers.get('X-Total'),
            10
          );
          pagination.current.perPage = parseInt(
            data.response.headers.get('X-Per-Page'),
            10
          );
          pagination.current.page = parseInt(
            data.response.headers.get('X-Page'),
            10
          );
          pagination.current.pages = Math.ceil(
            pagination.current.total / pagination.current.perPage
          );
          return data.data;
        }
        return data;
      },
      onSuccess: (data: any) => {
        // If the data we got back is an array and the id === '/', that means we've requested an index endpoint
        // if prefetchData is true, then prefetch all the results so they're ready in the cache
        if (Array.isArray(data) && id === '/' && config.prefetchData) {
          data.forEach((model) => {
            const { id } = model;
            const queryKey = generateQueryKey(id, resource, undefined, embed);
            queryClient.fetchQuery(
              queryKey,
              () => getData(resource, embed, { queryKey }, snakeCaseOptions),
              { ...config, initialData: model }
            );
          });
        }
      },
      retry,
    }
  );

  // To update the model
  const updateResult = useMutation(
    (args) => updateData(resource, args, queryResult.data?.updatedAt),
    {
      onMutate: (data) =>
        updateCacheOptimistically(queryClient, queryKey, data),
      onError: (error, variables, previousVersion) =>
        onMutationError(
          queryClient,
          queryKey,
          error,
          variables,
          previousVersion
        ),
      onSuccess: (data, variables, previousVersion) =>
        onUpdateSuccess(
          queryClient,
          queryKey,
          data,
          variables,
          previousVersion
        ),
      retry,
    }
  );

  // To delete the model
  const deleteResult = useMutation((id: number) => deleteData(resource, id), {
    onMutate: () => removeFromCacheOptimistically(queryClient, queryKey),
    onError: (error, variables, previousVersion) =>
      onMutationError(queryClient, queryKey, error, variables, previousVersion),
    onSuccess: () => onDeleteSuccess(queryClient, queryKey),
    retry,
  });

  // Update only the cache, without changing anything in the backend
  const updateCacheOnly = useCallback(
    (data: any) => {
      // Get a copy of the current value so we merge our changes into it
      const previousEntry = queryClient.getQueryData<{}>(queryKey);
      // Convert the data from the snake-case the API expcets to camelCase that we prefer
      const camelCasedNewVersion = snakeToCamelCase(data) as any;
      camelCasedNewVersion.updatedAt = new Date().toISOString();
      camelCasedNewVersion.isOptimistic = true;

      const mergedData = { ...previousEntry, ...camelCasedNewVersion };
      // Optimistically update the cache
      queryClient.setQueryData(queryKey, mergedData);
      return mergedData;
    },
    [queryClient, queryKey]
  );

  const { data, ...query } = queryResult;

  return {
    query,
    pagination: pagination.current,
    data,
    isSuccess:
      queryResult.isSuccess || updateResult.isSuccess || deleteResult.isSuccess,
    isLoading:
      queryResult.isLoading || updateResult.isLoading || deleteResult.isLoading,
    isError:
      queryResult.isError || updateResult.isError || deleteResult.isError,
    error: queryResult.error || updateResult.error || deleteResult.error,
    isUpdating: updateResult.isLoading,
    isUpdateSuccess: updateResult.isSuccess,
    isUpdateError: updateResult.isError,
    isFetching: queryResult.isFetching,
    reset: updateResult.reset,
    update: updateResult.mutateAsync,
    delete: deleteResult.mutateAsync,
    refetch: query.refetch,
    updateCacheOnly,
  };
};

export default useQueryCache;
